import React from "react";
import "./Spinner.style.scss";

interface SpinnerProps {
  size?: "small" | "medium" | "large"; // Optional size prop
  color?: string; // Optional color prop
  className?: string; // Optional additional className
}

const Spinner: React.FC<SpinnerProps> = ({
  size = "medium",
  color = "#3498db",
  className = "",
}) => {
  return (
    <div
      className={`spinner ${size} ${className}`}
      style={{ borderColor: `${color} transparent transparent transparent` }}
    ></div>
  );
};

export default Spinner;
