import React from "react";
import { format } from "date-fns";
import "./PatientTable.scss";
import ChatActiveIcon from "../../assets/icons/chat_active.png";
import AssessmentIcon from "../../assets/icons/assessment.png";

const PatientTable = ({ patients, onPatientClick }: any) => {
  const sendMessageToParent = (type: string, patient?: any, e?: any) => {
    // Sending a message to the parent window
    switch (type) {
      case "CHAT_CLICKED":
        window.parent.postMessage(
          {
            messageType: type,
          },
          "*"
        );
        break;
      case "ASSESSMENT_CLICKED":
        window.parent.postMessage(
          {
            messageType: type,
            assessmentData: { patient, e },
          },
          "*"
        );
        break;
    }
  };
  const renderActionButtons = (patient: any) => {
    return (
      <div className="action-buttons">
        <button
          className="action-btn chat"
          onClick={(e) => {
            e.stopPropagation();
            sendMessageToParent("CHAT_CLICKED");
            // Handle chat
          }}
        >
          <img
            src={ChatActiveIcon}
            style={{ width: "20px", height: "20px" }}
            alt="Chat"
            title="Chat"
          />
        </button>
        <button
          className="action-btn video"
          onClick={(e) => {
            e.stopPropagation();
            sendMessageToParent("ASSESSMENT_CLICKED", patient);
            // Handle video call
          }}
        >
          <img
            src={AssessmentIcon}
            style={{ width: "20px", height: "20px" }}
            alt="Assessment"
            title="Assessment"
          />
        </button>
      </div>
    );
  };

  return (
    <div className="patient-table">
      <div className="table-header">
        <div className="header-cell">No.</div>
        <div className="header-cell">Patient Details</div>
        <div className="header-cell">Phone No.</div>
        <div className="header-cell">Disease</div>
        <div className="header-cell">Indication</div>
        <div className="header-cell">Last Activity</div>
        <div className="header-cell">Plan Expiry</div>
        <div className="header-cell">Current Plan</div>
        <div className="header-cell">Actions</div>
      </div>

      <div className="table-body">
        {patients.map((patient: any, index: any) => (
          <div
            key={index.toString()}
            className="table-row"
            onClick={() =>
              onPatientClick(patient.patient_id, patient.get_active_id)
            }
          >
            <div className="cell" key={"1"}>
              {index + 1}
            </div>
            <div
              className="cell patient-info"
              key={"2"}
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
              }}
            >
              <div className="name" key={"3"}>
                {patient.name}
              </div>
              <div className="details" key={"4"}>
                {patient.age} years,{" "}
                {patient.gender === "M" ? "Male" : "Female"}
              </div>
            </div>
            <div className="cell" key={"10"}>
              {patient.contact_no}
            </div>
            <div className="cell" key={"5"}>
              {patient.disease}
            </div>
            <div className="cell" key={"6"}>
              {patient.medical_condition_name}
            </div>
            <div className="cell" key={"7"}>
              <div className="last-active-info">
                <div>
                  {format(new Date(patient.last_active), "dd MMM yyyy")}
                </div>
                <div className="timestamp">
                  {format(new Date(patient.last_active), "HH:mm")}
                </div>
              </div>
            </div>
            <div className="cell" key={"8"}>
              {patient.days_to_plan_expiry}
            </div>
            <div className="cell plan-info" key={"9"}>
              <div>{patient.plan_name}</div>
              <div className="expiry">
                Expires: {format(new Date(patient.expiry_date), "dd MMM yyyy")}
              </div>
            </div>
            <div className="cell">{renderActionButtons(patient)}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default PatientTable;
