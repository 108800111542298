import { FunctionComponent, useEffect, useState } from "react";
import patientsIcon from "../../assets/icons/patients.svg";
import "./MainSideNavPanel.css";
import { Link } from "react-router-dom";

type MainSideNavPanelProps = {
  onTabClick?: (tab: string) => void;
  journey: string;
};

const MainSideNavPanel: FunctionComponent<MainSideNavPanelProps> = ({
  onTabClick,
  journey,
}) => {
  const [selectedTab, setSelectedTab] = useState("all-patients");
  useEffect(() => {
    setSelectedTab(journey || selectedTab);
  });
  const handleTabClick = (tab: string) => {
    setSelectedTab(tab);
    onTabClick?.(tab || journey);
  };

  return (
    <div className="main-side-nav-panel">
      <div
        className="frame-parent1"
        onClick={() => handleTabClick("all-patients")}
      >
        <div className="patients-wrapper">
          <img
            className="patients-icon"
            loading="lazy"
            alt=""
            src={patientsIcon}
          />
        </div>
        <div className="frame-child" />
        <div className="menu">New Patients</div>
        {selectedTab === "all-patients" && <div className="selector1" />}
      </div>
      <div
        className="frame-parent2"
        onClick={() => handleTabClick("verified-patients")}
      >
        <div className="patients-wrapper">
          <img
            className="patients-icon"
            loading="lazy"
            alt=""
            src={patientsIcon}
          />
        </div>
        <div className="frame-child" />
        <div className="menu">Verified Patients</div>
        {selectedTab === "verified-patients" && <div className="selector2" />}
      </div>
      <div className="frame-parent3" onClick={() => handleTabClick("chat-app")}>
        <div className="patients-wrapper">
          <img
            className="patients-icon"
            loading="lazy"
            alt=""
            src={patientsIcon}
          />
        </div>
        <div className="frame-child" />
        <div className="menu">Chat App</div>
        {selectedTab === "chat-app" && <div className="selector3" />}
      </div>
    </div>
  );
};

export default MainSideNavPanel;
