// TODO: delete this file post goodflip launch.

import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import RecordsPage from "./RecordsPage";
import LOCAL_STORAGE from "../../../constants/localStorage";

const RecordsPageWrapper = () => {
  // const [status, setStatus] = useState("INITILISE")
  const [isLoading, setIsLoading] = useState(true);
  const params = useParams();

  console.log(params);

  useEffect(() => {
    const patient_id: any = params.id || params?.patient_id;
    localStorage.setItem(LOCAL_STORAGE.PATIENT_ID, patient_id);
    const token: any = params.token;
    localStorage.setItem(LOCAL_STORAGE.TOKEN, token);

    console.log("patient_id", patient_id);
    console.log("token", token);

    console.log("patient_id", patient_id);
    console.log("token", token);

    setIsLoading(false);
  }, []);

  if (isLoading) return null;

  return (
    <div>
      <RecordsPage />
    </div>
  );
};

export default RecordsPageWrapper;
