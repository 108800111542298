/**
 *
 * return the following
 * mutate - a function handle to intiate the api call
 * isLoading - a boolean value which will set true while fetching the data and set to false on success or error
 * response - a response object from axios
 * error - an error object from axios
 *
 * usage
 *
 * const { mutate: getData, isLoading, response, error } = useApi(apiFunc); // apiFunc is defined from api folder.
 * // example
 * useEffect(() => {
 *   getData(params); // params if any.
 * })
 *
 * useEffect(() => {
 *  if(response) {
 *      if(response.code === 1) {
 *          // handle success
 *      }
 *  } else {
 *      // handle error
 *  }
 * }, [response, error])
 *
 */
import { useState } from "react";

const useApi = (api = (params: any) => {}) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [response, setResponse] = useState<any>(null);
  const [error, setError] = useState<any>(null);

  const mutate = async (params?: any) => {
    setIsLoading(true);
    const startTime = Date.now(); // Track the start time

    try {
      const response: any = await api(params); // Await the API call
      const elapsedTime = Date.now() - startTime; // Calculate the elapsed time
      const delay = Math.max(0, 1000 - elapsedTime); // Determine the delay (minimum 1 second)

      // Apply the delay if necessary
      setTimeout(() => {
        if (Number(response?.code) === 1) {
          setResponse(response);
          setError(null);
        } else {
          setResponse(null);
          setError(response);
        }
        setIsLoading(false); // Set loading to false after the delay
      }, delay);
    } catch (error) {
      const elapsedTime = Date.now() - startTime;
      const delay = Math.max(0, 1000 - elapsedTime);

      // Apply the delay for error handling
      setTimeout(() => {
        setError(error);
        setIsLoading(false);
      }, delay);
    }
  };

  return {
    mutate,
    isLoading,
    response,
    error,
  };
};

export default useApi;
