import React from "react";

import Spinner from "../Spinner/Spinner";

import "./Button.style.scss";

interface Props {
  label?: String;
  children?: React.ReactNode;
  className?: string;
  variant?: "primary" | "secondary" | "outlined";
  onClick: (params: any) => any;
  loading?: boolean;
  disabled?: boolean;
  fullWidth?: boolean;
}

const Button: React.FC<Props> = ({
  label,
  children,
  className,
  variant = "primary",
  onClick,
  loading,
  disabled,
  fullWidth = false,
}) => {
  return (
    <button
      className={`button ${variant} ${className} ${
        fullWidth ? "full-width" : ""
      }`}
      onClick={onClick}
      disabled={disabled}
    >
      {(loading ? (
        <Spinner color={disabled ? "#777" : "#760fb2"} size="small" />
      ) : (
        label
      )) || children}
    </button>
  );
};

export default Button;
