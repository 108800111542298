import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import "./PatientScreen.scss";
import PatientTable from "./PatientTable";
import { usePatient } from "../../hooks/usePatient";
import { Modal, Pagination } from "antd";
import Loader from "../../atoms/Loader";

const PatientScreen = () => {
  const navigate = useNavigate();
  const params = useParams();

  const [searchTerm, setSearchTerm] = useState("");
  const [patientListPagination, setPatientListPagination] = useState({
    page: 1,
    limit: 10,
    total: 0,
  });

  useEffect(() => {
    const token: any = params.token;
    localStorage.setItem("token", token);
  }, [params]);

  const {
    patients,
    loading,
    totalActive,
    activeView,
    setActiveView,
    totalPatients,
  } = usePatient(searchTerm, patientListPagination);

  const handleSearch = (e: any) => {
    const value = e.target.value;
    setSearchTerm((prevState: any) => {
      if ((!prevState && value) || (prevState && value)) {
        setPatientListPagination((prev: any) => ({
          ...prev,
          page: 1,
        }));
        return value;
      }
      return value;
    });
    setSearchTerm(value);
  };

  const paginatedPatients = React.useMemo(() => {
    setPatientListPagination((prev) => ({
      ...prev,
      total: patients.length,
    }));

    return patients;
  }, [
    patients.length,
    patientListPagination.page,
    patientListPagination.limit,
  ]);

  const patientListPaginationChange = (page: number, pageSize: number) => {
    setPatientListPagination((prev) => ({
      ...prev,
      page,
      limit: pageSize,
    }));
  };

  const redirectToPatient = (patientId: any, getActyvId: any) => {
    // navigate(`/patient-detail/${patientId}`);
    window.parent.postMessage(
      {
        messageType: "PATIENT_CLICKED",
        data: { patientId, getActyvId },
      },
      "*"
    );
  };

  return (
    <div className="patient-page">
      <div className="content-wrapper">
        <main className="main-content">
          <div
            className="page-header"
            style={{
              display: "flex",
              flexDirection: "row-reverse",
            }}
          >
            {activeView !== "favorite" && (
              <div className="search-filter" style={{ display: "flex" }}>
                <input
                  type="search"
                  value={searchTerm}
                  onChange={handleSearch}
                  placeholder="Search patients..."
                  className="search-input"
                />
              </div>
            )}
          </div>

          <div className="view-selector">
            <button
              className={`view-btn ${activeView === "call" ? "active" : ""}`}
              onClick={() => setActiveView("call")}
            >
              Active Patients {totalActive > 0 && `(${totalActive})`}
            </button>
            <button
              className={`view-btn ${
                activeView === "inactive" ? "active" : ""
              }`}
              onClick={() => setActiveView("inactive")}
            >
              Plan Expired Patients
            </button>
            <button
              className={`view-btn ${
                activeView === "favorite" ? "active" : ""
              }`}
              onClick={() => setActiveView("favorite")}
            >
              Manage Patient List
            </button>
          </div>

          {loading ? (
            <Loader noBackground={true} />
          ) : (
            <PatientTable
              patients={paginatedPatients}
              onPatientClick={(id: any, get_actyv_id: any) =>
                redirectToPatient(id, get_actyv_id)
              }
            />
          )}
          <div
            style={{
              marginTop: "20px",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Pagination
              //   style={{  }}
              defaultCurrent={1}
              total={totalPatients}
              onChange={(page: any, pageSize: any) =>
                patientListPaginationChange(page, pageSize)
              }
              current={patientListPagination?.page}
              showSizeChanger={true}
            />
          </div>
        </main>
      </div>
    </div>
  );
};

export default PatientScreen;
