import React, { useRef, useState } from "react";

import { truncate } from "../../utils/common";

import "./FileUpload.style.scss";

interface FileUploadProps {
  onFileUpload: (files: Array<File>) => void;
  error?: boolean;
  helperText?: string;
  acceptedFileTypes?: Array<"pdf" | "png" | "jpeg">;
}

const FileUpload: React.FC<FileUploadProps> = ({
  onFileUpload,
  error = false,
  helperText = "",
  acceptedFileTypes = ["pdf"],
}) => {
  const [files, setFiles] = useState<Array<File>>();

  const videoRef = useRef<HTMLVideoElement>(null);
  const [cameraActive, setCameraActive] = useState(false);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      setFiles(Array.from(event.target.files));
      onFileUpload(Array.from(event.target.files));
    }
  };

  const handleCameraClick = async () => {
    try {
      // Request camera access
      const stream = await navigator.mediaDevices.getUserMedia({ video: true });
      if (videoRef.current) {
        videoRef.current.srcObject = stream;
        videoRef.current.play();
        setCameraActive(true);
      }
    } catch (error) {
      console.error("Error accessing camera:", error);
    }
  };

  //   const handleTakeSnapshot = () => {
  //     if (videoRef.current) {
  //       const video = videoRef.current;
  //       const canvas = document.createElement("canvas");
  //       canvas.width = video.videoWidth;
  //       canvas.height = video.videoHeight;
  //       const context = canvas.getContext("2d");

  //       if (context) {
  //         context.drawImage(video, 0, 0, canvas.width, canvas.height);
  //         canvas.toBlob((blob) => {
  //           if (blob) {
  //             const file = new File([blob], "captured_image.jpg", {
  //               type: "image/jpeg",
  //             });
  //             onFileUpload(file);
  //           }
  //         });
  //       }

  //       // Stop the camera after capturing the snapshot
  //       const stream = video.srcObject as MediaStream;
  //       stream.getTracks().forEach((track) => track.stop());
  //       video.srcObject = null;
  //       setCameraActive(false);
  //     }
  //   };

  return (
    <div className="file-upload-container">
      <p className="file-format-info">
        File should be {acceptedFileTypes.map((type) => `${type}`).join(",")} *
      </p>
      <div className={`file-upload-box ${error ? "error" : ""}`}>
        {cameraActive ? (
          <div className="camera-view">
            <video ref={videoRef} className="video-feed" />
            <button
              className="snapshot-button"
              // onClick={handleTakeSnapshot}
            >
              Take Snapshot
            </button>
          </div>
        ) : (
          <>
            {/* <div className="upload-option" onClick={handleCameraClick}>
              <span className="icon">📷</span>
              <p>Take picture of your document</p>
            </div>
            <span className="divider">or</span> */}
            <div className="upload-option">
              <label htmlFor="file-upload" className="browse-file">
                Browse file
              </label>
              <input
                id="file-upload"
                type="file"
                accept={acceptedFileTypes.map((type) => `.${type}`).join(",")}
                onChange={handleFileChange}
                hidden
              />
            </div>
          </>
        )}
      </div>

      {helperText && (
        <span className={`helper-text ${error ? "error-text" : ""}`}>
          {helperText}
        </span>
      )}

      {files && (
        <div className="file-name">
          <b>File:</b> {files?.map((file) => truncate(file?.name)).join(",")}
        </div>
      )}
    </div>
  );
};

export default FileUpload;
