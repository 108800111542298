import React from 'react'

import "./ExercisePlanPage.scss"

const ExercisePlanPage = () => {
  return (
    <div>ExercisePlanPage</div>
  )
}

export default ExercisePlanPage