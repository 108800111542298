import React from 'react'

import "./CustomPlanPage.scss"

const CustomPlanPage = () => {
  return (
    <div>CustomPlanPage</div>
  )
}

export default CustomPlanPage