import React, { useEffect, useState } from "react";
import "./DietAssessmentForm.scss";
import Input from "../../atoms/Input/Input";
import { BackIcon } from "../../assets/icons";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import ReactSelect from "react-select";
import API from "../../services/rest/api";
import moment from "moment";
import DietAssessmentView from "../../molecules/DietAssessmentView/DietAssessmentView";
import { Checkbox, Select } from "antd";

type AssesmentType = {
  order_no?: number;
  question?: string;
  answer?: any;
  options?: any;
};

const DietAssessmentForm = () => {
  const navigate = useNavigate();
  const params = useParams();
  const location = useLocation();
  const [error, setError] = useState<string>("");
  const [diseaseOptions, setDiseaseOptions] = useState<any>([]);
  const [selectedDisease, setSelectedDisease] = useState<any>(null);
  const [addQue, setAddQue] = useState<boolean>(false);
  const [newQuestion, setNewQuestion] = useState<string>("");
  const [questionAnswers, setQuestionAnswers] = useState<any>([]);
  // const [assessmentName, setAssessmentName] = useState<string>('')
  const [patientAssessmentId, setPatientAssessmentId] = useState<string>("");
  const [patientData, setPatientData] = useState<any>(null);
  const [patientID, setPatientID] = useState<any>(null);

  //assessment states
  const [patientAssessments, setPatientAssessments] = useState<any>([]);
  const [assessmentOptions, setAssessmentOptions] = useState<any>([]);
  const [selectedAssessment, setSelectedAssessment] = useState<any>(null);
  const [userInfo, setUserInfo] = useState<any>(null);
  const [selected, setSelected] = useState([]);
  const [submitLoader, setSubmitLoader] = useState(false);

  // const userInfo = JSON.parse(localStorage.getItem('userInfo') || '') || {}

  const token = localStorage.getItem("token");

  useEffect(() => {
    if (location?.state?.data) {
      // setAssessmentName(location?.state?.data?.assessment_name)
      setSelectedDisease(location?.state?.data?.disease_type);
      setPatientAssessmentId(location?.state?.data?.patient_assessment_id);
      setQuestionAnswers(location?.state?.data?.answer_list);
      setPatientID(location?.state?.data?.patient_id);
    } else {
      // setAssessmentName('')
      setSelectedDisease("");
      setPatientAssessmentId("");
    }
  }, [location?.state?.data]);

  useEffect(() => {
    const patient_id: any = params.id || params?.patientId;
    localStorage.setItem("patient_id", patient_id);
    const token: any = params.token;
    localStorage.setItem("token", token);
    getProfileDetails();
  }, [params]);

  const getProfileDetails = async () => {
    try {
      const userInfo = await API.GetProfileDetails();
      console.log(userInfo);
      if (userInfo?.code === "1") {
        setUserInfo(userInfo?.data);
        localStorage.setItem("userInfo", JSON.stringify(userInfo?.data));
      }
    } catch (error) {}
  };

  useEffect(() => {
    getAllDiseases();
    if (!location?.state?.data) {
      //getPatientAssessments()
      getPatientData();
    }
  }, []);

  useEffect(() => {
    if (!location?.state?.data && selectedDisease) {
      getPatientAssessments();
      getQuestions();
    }
  }, [selectedDisease]);

  //function to get all diseases
  const getAllDiseases = async () => {
    try {
      const data = await API.GetDiseasesData();
      if (data?.code === "1") {
        setDiseaseOptions(data?.data);
      } else {
        setDiseaseOptions([]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getLastCount = () => {
    let updatedAnswerList: any = [];
    questionAnswers.forEach((res: any) => {
      updatedAnswerList = [...updatedAnswerList, ...(res.questions_list || [])];
    });
    return updatedAnswerList.pop()?.order_no || 0;
  };

  //function to add question
  const addNewQuestion = () => {
    let lastCount = getLastCount();
    let allquestions = [...questionAnswers];
    allquestions[allquestions.length - 1].questions_list.push({
      order_no: lastCount + 1,
      section_type: "extra",
      section_label: "Extra",
      question: newQuestion,
      answer: "",
    });

    // allquestions.push({
    //     order_no: allquestions.length + 1,
    //     question: newQuestion,
    //     answer: ''
    // });
    // setQuestionAnswers(allquestions)
    setNewQuestion("");
    setAddQue(false);
  };

  //function to Get Question
  const getQuestions = async () => {
    try {
      let questionsRes = await API.GetAssessmentQuestions({
        disease_type: selectedDisease,
        patient_id: params.id || params?.patientId,
        assessment_type: "diet",
      });
      if (questionsRes?.code === "1") {
        if (questionsRes?.data.length > 0) {
          questionsRes.data[0].questions_list[0].answer = patientData?.name;
          questionsRes.data[0].questions_list[1].answer =
            patientData?.patient_link_doctor_details &&
            patientData?.patient_link_doctor_details.length &&
            patientData?.patient_link_doctor_details[0].name;
          questionsRes.data[0].questions_list[2].answer =
            userInfo?.first_name + " " + userInfo?.last_name;
          questionsRes.data[0].questions_list[3].answer =
            patientData?.patient_age;
          questionsRes.data[0].questions_list[4].answer =
            patientData?.gender === "M" ? "Male" : "Female";
          setQuestionAnswers(questionsRes?.data);
          // await getPatientData()
        }
      } else {
        setQuestionAnswers([]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  //funtion to get patient data
  const getPatientData = async () => {
    try {
      let payload = {
        patient_id: params.id || params?.patientId,
      };
      const data = await API.GetPatientData(payload);
      if (data?.code === "1") {
        setPatientData(data?.data);
        setSelectedDisease(data?.data?.medical_condition_name);
      }
    } catch (error) {
      console.log(error);
    }
  };

  //function to sumbit assessment
  const submitAssessment = async () => {
    setSubmitLoader(true);
    if (location?.state?.data) {
      try {
        const payload = {
          patient_assessment_id: patientAssessmentId,
          patient_id: patientID,
          answer_list: questionAnswers,
        };
        const data = await API.UpdateAssessmentForm(payload);
        if (data?.code === "1") {
          let encodedtoken = encodeURIComponent(token || "");
          if (location?.state?.screen === "assessmentForm") {
            window.parent.postMessage("SaveAssessment", "*");
            navigate(
              `/patients/${userInfo?.health_coach_id}/assessment/${patientID}/${encodedtoken}`
            );
            window.location.reload();
          } else {
            navigate(`/homescreen/${patientID}/${encodedtoken}`);
          }
          setError("");
          // setAssessmentName('')
        } else {
          setError(data?.message);
        }
      } catch (error) {}
    } else {
      try {
        const payload = {
          assessment_date: moment(new Date()).format("YYYY-MM-DD"),
          patient_id: params?.patientId,
          disease_type: selectedDisease,
          answer_list: questionAnswers,
        };

        const data = await API.SubmitAssessmentForm(payload);
        if (data?.code === "1") {
          window.parent.postMessage("SaveAssessment", "*");
          window.location.reload();
          setError("");
          // setAssessmentName('')
        } else {
          setError(data?.message);
        }
      } catch (error) {
        console.log(error);
      }
    }
    setSubmitLoader(false);
  };

  //funtion to change answer
  const changeAnswer = (e: any, order_no: any, sec_type: any) => {
    let tempQueArray = [...questionAnswers];

    const mainIndex = tempQueArray.findIndex(
      (item) => item?.section_type === sec_type
    );
    const index = tempQueArray[mainIndex]?.questions_list.findIndex(
      (item: any) => item.order_no === order_no
    );

    if (index !== -1) {
      tempQueArray[mainIndex].questions_list[index].answer = e.target.value;
      setQuestionAnswers(tempQueArray);
    } else {
      console.log("Order number not found.");
    }
  };

  //funtion to Other change answer
  const otherChangeAnswer = (e: any, order_no: any, sec_type: any) => {
    let tempQueArray = [...questionAnswers];

    const mainIndex = tempQueArray.findIndex(
      (item) => item?.section_type === sec_type
    );
    const index = tempQueArray[mainIndex]?.questions_list.findIndex(
      (item: any) => item.order_no === order_no
    );

    if (index !== -1) {
      tempQueArray[mainIndex].questions_list[index].other_answer =
        e.target.value;
      setQuestionAnswers(tempQueArray);
    } else {
      console.log("Order number not found.");
    }
  };

  //funtion to get patient assessments
  const getPatientAssessments = async () => {
    try {
      let assessmentData = await API.GetPatientAssessments({
        patient_id: params.id || params?.patientId,
        disease_type: selectedDisease,
        assessment_type: "diet",
      });
      if (assessmentData?.code === "1") {
        let tempAssessmentData = assessmentData?.data?.map((data: any) => {
          return {
            ...data,
            label: data?.assessment_name,
            value: data?.patient_assessment_id,
          };
        });
        setAssessmentOptions(tempAssessmentData);
        setSelectedAssessment((assessmentData?.data || []).pop());
        setPatientAssessments(assessmentData?.data);
      } else {
        setPatientAssessments([]);
        setAssessmentOptions([]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const onMultiChange = (order_no: any, op: any, sec_type: any) => {
    let tempQueArray: any = [...questionAnswers];

    const mainIndex = tempQueArray.findIndex(
      (item: any) => item?.section_type === sec_type
    );
    const index = tempQueArray[mainIndex].questions_list.findIndex(
      (item: any) => item.order_no === order_no
    );

    if (index !== -1) {
      if (
        tempQueArray[mainIndex]?.questions_list[index]?.answer &&
        tempQueArray[mainIndex]?.questions_list[index]?.answer !== ""
      ) {
        const answerArray =
          tempQueArray[mainIndex].questions_list[index].answer?.split("<->");
        if (answerArray.includes(op?.label)) {
          const updatedAnswerArray = answerArray.filter(
            (item: any) => item !== op?.label
          );
          tempQueArray[mainIndex].questions_list[index].answer =
            updatedAnswerArray.join("<->");

          if (op?.label === "Other") {
            tempQueArray[mainIndex].questions_list[index].other_answer = null;
          }
          setQuestionAnswers(tempQueArray);
        } else {
          tempQueArray[mainIndex].questions_list[index].answer =
            tempQueArray[mainIndex].questions_list[index].answer +
            "<->" +
            op?.label;
          setQuestionAnswers(tempQueArray);
        }
      } else {
        tempQueArray[mainIndex].questions_list[index].answer = op?.label;
        setQuestionAnswers(tempQueArray);
      }
    } else {
      console.log("Order number not found.");
    }
  };

  const customStyles = {
    control: (provided: any, state: any) => ({
      ...provided,
      background: "#fff",
      minHeight: "30px",
      height: "33px",
      boxShadow: state.isFocused ? null : null,
      borderRadius: "5px",
      width: "300px",
      cursor: "pointer",
      border: "1px solid #7E22B7",
      color: "#7E22B7",
    }),

    valueContainer: (provided: any, state: any) => ({
      ...provided,
      height: "30px",
      padding: "3px 10px",
      fontSize: "12px",
    }),

    input: (provided: any, state: any) => ({
      ...provided,
      margin: "0px",
    }),
    indicatorSeparator: (state: any) => ({
      display: "none",
    }),
    indicatorsContainer: (provided: any, state: any) => ({
      ...provided,
      height: "30px",
    }),
    menuPortal: (base: any) => ({
      ...base,
      fontSize: "8px",
    }),
  };

  const customStyles2 = {
    control: (provided: any, state: any) => ({
      ...provided,
      background: "#fff",
      minHeight: "30px",
      height: "33px",
      boxShadow: state.isFocused ? null : null,
      borderRadius: "5px",
      width: "200px",
      cursor: "pointer",
      border: "1px solid #7E22B7",
      color: "#7E22B7",
    }),

    valueContainer: (provided: any, state: any) => ({
      ...provided,
      height: "30px",
      padding: "3px 10px",
      fontSize: "12px",
    }),

    input: (provided: any, state: any) => ({
      ...provided,
      margin: "0px",
    }),
    indicatorSeparator: (state: any) => ({
      display: "none",
    }),
    indicatorsContainer: (provided: any, state: any) => ({
      ...provided,
      height: "30px",
    }),
    menuPortal: (base: any) => ({
      ...base,
      fontSize: "8px",
    }),
  };

  return (
    <div className="assessment-form-container">
      {/* <div onClick={() => navigate(-1)} style={{ display: 'flex', alignItems: 'center' }}>
                <BackIcon className="back-icon" />
            </div> */}
      <p className="assessment-form-heading">Patient Assessment Form</p>

      {/* <div className="assessment-name-container">
                <span style={{ fontSize: '16px', fontWeight: 700, color: '#760fb2' }}>Assessment Name :</span>
                <input name="assessment_name" type="text" value={assessmentName} disabled={location?.state?.data ? true : false} onChange={(e) => setAssessmentName(e.target.value)} />
            </div> */}

      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div className="assessment-type">
          <span style={{ fontSize: "14px", fontWeight: 700, color: "#760fb2" }}>
            Select Disease
          </span>
          <ReactSelect
            classNamePrefix="select"
            name="color"
            theme={(theme) => ({
              ...theme,
              borderRadius: 6,
              colors: {
                ...theme.colors,
                primary25: "#F0E7F7",
                primary: "#760fb2",
              },
            })}
            options={diseaseOptions}
            onChange={(selectedOption: any) => {
              setSelectedDisease(selectedOption?.value);
            }}
            value={diseaseOptions.find(
              (val: any) => val.value == selectedDisease
            )}
            styles={customStyles}
            placeholder="Select Disease"
            isDisabled={location?.state?.data ? true : false}
          />
        </div>

        {!location?.state?.data && (
          <div className="assessment-type">
            <span
              style={{ fontSize: "14px", fontWeight: 700, color: "#760fb2" }}
            >
              Select Assessment
            </span>
            <ReactSelect
              classNamePrefix="select"
              name="color"
              theme={(theme) => ({
                ...theme,
                borderRadius: 6,
                colors: {
                  ...theme.colors,
                  primary25: "#F0E7F7",
                  primary: "#760fb2",
                },
              })}
              options={assessmentOptions}
              onChange={(selectedOption: any) => {
                setSelectedAssessment(selectedOption);
              }}
              value={assessmentOptions.find(
                (val: any) =>
                  val.value == selectedAssessment?.patient_assessment_id
              )}
              styles={customStyles}
              placeholder="Select Assessment"
              isDisabled={location?.state?.data ? true : false}
            />
          </div>
        )}
      </div>

      {!selectedAssessment?.patient_id || location?.state?.data ? (
        <>
          <div className="assessment-personal">
            <p className="assessment-personal-heading">Personal History</p>
            <div className="personal-que-ans">
              {questionAnswers[0]?.questions_list.map((d: any, idx: any) => {
                return (
                  <div className="personal-que-ans-inner">
                    <div className="personal-que-ans-left">
                      <span>
                        {d?.order_no}. {d?.question}
                      </span>{" "}
                      <span>:</span>
                    </div>
                    {d?.options?.length ? (
                      <>
                        {d?.is_multiselect == "Y" ? (
                          <div
                            style={{ display: "flex", flexDirection: "column" }}
                          >
                            {d?.options.map((op: any) => {
                              return (
                                <div
                                  style={{
                                    display: "flex",
                                    gap: "5px",
                                    marginLeft: "15px",
                                  }}
                                >
                                  <Checkbox
                                    onChange={() =>
                                      onMultiChange(
                                        d?.order_no,
                                        op,
                                        questionAnswers[0]?.section_type
                                      )
                                    }
                                    checked={d?.answer
                                      ?.split("<->")
                                      .includes(op?.label)}
                                  />
                                  <div>{op?.label}</div>
                                </div>
                              );
                            })}
                            {d?.answer?.split("<->").includes("Other") && (
                              <textarea
                                name={d?.question}
                                onChange={(e) =>
                                  otherChangeAnswer(
                                    e,
                                    d?.order_no,
                                    d?.section_type
                                  )
                                }
                                value={d?.other_answer}
                              />
                            )}
                          </div>
                        ) : (
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              margin: "5px 12px",
                            }}
                          >
                            <Select
                              style={{ width: 250 }}
                              onChange={(value: any) => {
                                let tempQueArray = [...questionAnswers];
                                const mainIndex = tempQueArray.findIndex(
                                  (item) =>
                                    item?.section_type ===
                                    questionAnswers[0].section_type
                                );
                                const index = tempQueArray[
                                  mainIndex
                                ]?.questions_list?.findIndex(
                                  (item: any) => item.order_no === d?.order_no
                                );

                                if (index !== -1) {
                                  tempQueArray[mainIndex].questions_list[
                                    index
                                  ].answer = value;
                                  setQuestionAnswers(tempQueArray);
                                } else {
                                  console.log("Order number not found.");
                                }
                              }}
                              value={
                                questionAnswers[0].questions_list[idx]?.answer
                              }
                              options={d?.options.map((op: any) => {
                                return { ...op, value: op?.label };
                              })}
                            />
                          </div>
                        )}
                      </>
                    ) : (
                      <input
                        name="patient_name"
                        type="text"
                        value={d?.answer}
                        onChange={(e) =>
                          changeAnswer(e, d?.order_no, "personal_history")
                        }
                      />
                    )}
                  </div>
                );
              })}
            </div>
          </div>

          <div className="assessment-other">
            {questionAnswers.slice(1).map((d: any, mainIndex: any) => {
              return (
                <>
                  <span className="assessment-other-heading">
                    {d?.section_label}
                  </span>
                  {d?.questions_list.map((que: any, idx: any) => {
                    return (
                      <div className="assessment-que-ans">
                        <span className="assessment-que">
                          {que?.order_no}. {que?.question}
                        </span>
                        {que?.options?.length ? (
                          <>
                            {que?.is_multiselect == "Y" ? (
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                }}
                              >
                                {que?.options.map((op: any) => {
                                  return (
                                    <div
                                      style={{
                                        display: "flex",
                                        gap: "5px",
                                        marginLeft: "15px",
                                      }}
                                    >
                                      <Checkbox
                                        onChange={() =>
                                          onMultiChange(
                                            que?.order_no,
                                            op,
                                            d?.section_type
                                          )
                                        }
                                        checked={que?.answer
                                          ?.split("<->")
                                          .includes(op?.label)}
                                      />
                                      <div>{op?.label}</div>
                                    </div>
                                  );
                                })}
                                {que?.answer
                                  ?.split("<->")
                                  .includes("Other") && (
                                  <textarea
                                    name={que?.question}
                                    onChange={(e) =>
                                      otherChangeAnswer(
                                        e,
                                        que?.order_no,
                                        d?.section_type
                                      )
                                    }
                                    value={que?.other_answer}
                                  />
                                )}
                              </div>
                            ) : (
                              <div
                                style={{ width: "200px", margin: "5px 15px" }}
                              >
                                <Select
                                  style={{ width: 250 }}
                                  onChange={(value: any) => {
                                    let tempQueArray = [...questionAnswers];
                                    const mainIndex = tempQueArray.findIndex(
                                      (item) =>
                                        item?.section_type === d?.section_type
                                    );
                                    const index = tempQueArray[
                                      mainIndex
                                    ]?.questions_list?.findIndex(
                                      (item: any) =>
                                        item.order_no === que?.order_no
                                    );
                                    tempQueArray[mainIndex].questions_list[
                                      index
                                    ].answer = value;
                                    setQuestionAnswers(tempQueArray);
                                  }}
                                  value={que?.answer}
                                  options={que?.options.map((op: any) => {
                                    return { ...op, value: op?.label };
                                  })}
                                />
                              </div>
                            )}
                          </>
                        ) : (
                          <textarea
                            name={que?.question}
                            value={que?.answer}
                            onChange={(e) =>
                              changeAnswer(e, que?.order_no, d?.section_type)
                            }
                          />
                        )}
                      </div>
                    );
                  })}
                </>
              );
            })}
          </div>

          {addQue ? (
            <div className="add-que-container">
              <textarea
                name="que"
                placeholder="add new question here..."
                onChange={(e) => setNewQuestion(e.target.value)}
              />
              <button
                className={
                  newQuestion?.length
                    ? "save-que-button"
                    : "save-que-button-disable"
                }
                onClick={() => addNewQuestion()}
                disabled={!newQuestion?.length}
              >
                Save
              </button>
            </div>
          ) : (
            <button className="add-que-button" onClick={() => setAddQue(true)}>
              + Add Question
            </button>
          )}

          {error && (
            <span style={{ color: "red", marginTop: "15px" }}>{error}</span>
          )}

          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "20px",
            }}
          >
            <button
              className="add-que-button"
              onClick={() => {
                if (location?.state?.data) {
                  let encodedtoken = encodeURIComponent(token || "");
                  navigate(`/homescreen/${params?.patientId}/${encodedtoken}`);
                } else {
                  window.parent.postMessage("cancelAssessment", "*");
                }
              }}
            >
              Cancel
            </button>
            <button
              className={
                submitLoader ? "save-que-button-disable" : "save-que-button"
              }
              onClick={submitAssessment}
              disabled={submitLoader}
            >
              Submit
            </button>
          </div>
        </>
      ) : (
        <DietAssessmentView
          ifHomeScreen={"assessmentForm"}
          showCloseButton={true}
          selectedAssessment={selectedAssessment}
          patient_id={patientID}
        />
      )}
    </div>
  );
};

export default DietAssessmentForm;
