import React, {
  ChangeEvent,
  FunctionComponent,
  useEffect,
  useState,
} from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import "./AllPatients.css";
import AppointmentCell from "../../molecules/CgAppointmentCell/AppointmentCell";
import MainSideNavPanel from "../../molecules/CgMainSideNavPanel/MainSideNavPanel";
import VerifiedPatientsCell from "../../molecules/CgVerifiedPatientsCell/VerifiedPatientsCell";
import { getDecryptedData } from "../../utils/common";
import API, { GetOverallPatientCount } from "../../services/rest/api";

const AllPatients: FunctionComponent = () => {
  const [userDetails, setUserDetails] = useState<any>(null);
  const [journey, setJourney] = useState<any>("all-patients");
  const [patients, setPatients] = useState<any[]>([]);
  const [verifiedPatients, setVerifiedPatients] = useState<any[]>([]);
  const [senderId, setSenderId] = useState<string | null>(null);
  const [chatAppUrl, setChatAppUrl] = useState<string>("");
  const navigate = useNavigate();
  const location: any = useLocation();
  const params = useParams();

  const [verifiedSearchQuery, setVerifiedSearchQuery] = useState<string>("");

  const handlePatientsSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
    setVerifiedSearchQuery(e.target.value);
  };

  const filterPatients = (patients: any, query: string) => {
    return patients.filter(
      (patients: any) =>
        patients.name.toLowerCase().includes(query.toLowerCase()) ||
        patients.contact_no.includes(query)
    );
  };

  const filteredPatients = filterPatients(patients, verifiedSearchQuery);

  const handlePrimaryButtonClick = (screen: string) => {
    navigate(`/${screen}`);
  };

  useEffect(() => {
    const token: any = params.token;
    localStorage.setItem(
      "token",
      location.state?.token === ":token" || location.state?.token === undefined
        ? token
        : location.state?.token
    );
    sessionStorage.setItem(
      "token",
      location.state?.token === ":token" || location.state?.token === undefined
        ? token
        : location.state?.token
    );
    setJourney(
      location.state?.journey === "" || location.state?.journey === undefined
        ? journey
        : location.state?.journey
    );
  }, []);

  const PatientListAPI = `${process.env.REACT_APP_API_URL}/api/v8/healthcoach/patient_list`;

  useEffect(() => {
    setVerifiedSearchQuery("");
    const fetchData = async () => {
      try {
        const token = location.state?.token ?? sessionStorage.getItem("token");
        const secretKey = "SfUyfAztruqg92sbm30rEIyHLNV7f5";
        const body = JSON.stringify({ page: "1", per_page: "500" });
        const response = await fetch(PatientListAPI, {
          method: "POST",
          headers: {
            "Content-Type": "text/plain",
            token: `${token}`,
            health_secret: secretKey,
          },
          body: body,
        });

        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }
        const data = await response.json();
        setPatients(data.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, [journey, params]);

  useEffect(() => {
    getProfileDetails();

    // Get chat app URL from environment variable
    const chatAppUrlFromEnv = process.env.REACT_APP_CHAT_APP_URL;
    // setSenderId(localStorage.getItem("health-coach-id"))
    if (chatAppUrlFromEnv) {
      let fullName = `${userDetails?.first_name} ${userDetails?.last_name}`;
      let hc_type = userDetails?.chief_hc === "Y" ? "chief" : "hc";
      setChatAppUrl(
        `${chatAppUrlFromEnv}?sender=${userDetails?.health_coach_id}&sender_type=health_coach&sender_name=${fullName}&hc_type=${hc_type}`
      );
    }
  }, [journey, params]);

  //funtion to get profile details
  const getProfileDetails = async () => {
    try {
      const userInfo = await API.GetProfileDetails();
      localStorage.setItem("userInfo", JSON.stringify(userInfo?.data));
      setUserDetails(userInfo?.data);
    } catch (error) {
      console.log("error", error);
    }
  };

  return (
    <div style={{ height: "100vh", width: "100vw", backgroundColor: "red" }}>
      <div className="desktop-1">
        <MainSideNavPanel
          onTabClick={(tab) => setJourney(tab)}
          journey={journey}
        />
        {journey === "all-patients" && (
          <>
            <div>
              <h3 className="products-list">Your Patients</h3>
              <input
                type="text"
                placeholder="Search by name or phone no."
                value={verifiedSearchQuery}
                onChange={handlePatientsSearchChange}
                className="patinets-search-bar"
              />
            </div>
            <div className="payment-number-frame">
              <div className="payment-number">#</div>
              <div className="appointment-cell-inner">
                <div className="payment-number1">Name</div>
              </div>
              <div className="appointment-cell-inner">
                <div className="payment-number2">Phone No</div>
              </div>
              <div className="appointment-cell-inner">
                <div className="payment-number3">Type</div>
              </div>
              <div className="appointment-cell-inner">
                <div className="payment-number4">Doctor Name</div>
              </div>
              <div className="appointment-cell-inner">
                <div className="payment-number5">PSP Name</div>
              </div>
              <div className="appointment-cell-inner">
                <div className="payment-number5">Log In Time</div>
              </div>
              <div className="appointment-cell-inner">
                <div className="payment-number5">Action</div>
              </div>
            </div>
            <div className="patients-table">
              {filteredPatients.length > 0 ? (
                filteredPatients
                  .filter(
                    (userData: {
                      visits: string | number | null | undefined;
                    }) =>
                      userData.visits === 0 ||
                      (userData.visits === "" &&
                        userData.visits === null &&
                        userData.visits === undefined)
                  )
                  .map((userData: any, index: any) => (
                    <AppointmentCell
                      index={index + 1}
                      name={userData.name}
                      contact={userData.contact_no}
                      visitType={userData.user_type}
                      doctor={userData.doctor_name}
                      PSP_Name={userData.PSP_Name}
                      loginTime={userData.last_active}
                      // status={userData.status}
                      userData={userData}
                      primaryButton="Complete Profile"
                    />
                  ))
              ) : (
                <p>No verified patients found.</p>
              )}
            </div>
            <div className="desktop-1-item" />
          </>
        )}
        {journey === "verified-patients" && (
          <>
            <div>
              <h3 className="products-list">Verified Patients</h3>
              <input
                type="text"
                placeholder="Search by name or phone no."
                value={verifiedSearchQuery}
                onChange={handlePatientsSearchChange}
                className="patinets-search-bar"
              />
            </div>
            <div className="payment-number-frame">
              <div className="payment-number">#</div>
              <div className="appointment-cell-inner">
                <div className="payment-number1">Name</div>
              </div>
              <div className="appointment-cell-inner">
                <div className="payment-number2">Phone No</div>
              </div>
              <div className="appointment-cell-inner">
                <div className="payment-number3">Type</div>
              </div>
              <div className="appointment-cell-inner">
                <div className="payment-number4">Current Cycle No.</div>
              </div>
              <div className="appointment-cell-inner">
                <div className="payment-number5">ACTION</div>
              </div>
            </div>
            <div className="patients-table">
              {filteredPatients.length > 0 ? (
                filteredPatients
                  .filter((userData: { visits: number }) => userData.visits > 0)
                  .map((userData: any, index: any) => (
                    <VerifiedPatientsCell
                      key={userData.id}
                      index={index + 1}
                      name={userData.name}
                      contact={userData.contact_no}
                      visitType={userData.user_type}
                      doctor={userData.doctor_name}
                      userData={userData}
                      primaryButton="Verify"
                      visitsNo={userData.visits}
                    />
                  ))
              ) : (
                <p>No verified patients found.</p>
              )}
            </div>
            <div className="desktop-1-item" />
          </>
        )}
        {journey === "chat-app" && (
          <iframe
            className="chat-app"
            src={chatAppUrl}
            title="Chat App"
            style={{ border: "none", height: "100%" }}
            allow="notifications"
            sandbox="allow-same-origin allow-scripts allow-popups allow-popups-to-escape-sandbox"
          />
        )}
      </div>
    </div>
  );
};

export default AllPatients;
