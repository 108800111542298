import React, { useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";

import "./PatientLayout.scss";

import UserProfileCard from "../../../molecules/PatientDetailsV2/UserProfileCard/UserProfileCard";

const PatientLayout: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams()
  const [subMenuOpen, setSubMenuOpen] = useState<string | null>(null);

  const tabs = [
    { label: "Progress", path: "progress" },
    { label: "Assign Tasks", path: "assign-tasks" },
    { label: "Vitals", path: "vitals" },
    { label: "Records", path: "records" },
  ];

  const activeTab =
    tabs.find((tab) => location.pathname.includes(tab.path))?.path ||
    "progress";

  const leftMenu = {
    progress: [
      { label: "Main Goals", path: "progress/main-goals" },
      { label: "Psychologist", path: "progress/psychologist" },
      { label: "Nutritionist", path: "progress/nutritionist" },
      { label: "Success Coach", path: "progress/success-coach" },
    ],
    "assign-tasks": [
      {
        label: "Exercise",
        path: "assign-tasks/exercise",
        submenu: [
          {
            label: "Exercise Plan",
            path: "assign-tasks/exercise/exercise-plan",
          },
          { label: "Custom Plan", path: "assign-tasks/exercise/custom-plan" },
        ],
      },
      { label: "Diet", path: "assign-tasks/diet" },
      { label: "Steps", path: "assign-tasks/steps" },
      { label: "Water Intake", path: "assign-tasks/water-intake" },
      { label: "Sleep", path: "assign-tasks/sleep" },
    ],
  };

  useEffect(() => {
    console.log("Mounting");
    if (params.token) {
      const patient_id: any = params.id || params?.patientId
      localStorage.setItem('patient_id', patient_id)
      const token: any = params.token
      localStorage.setItem('token', token)

      console.log("Navigating to progress/main-goals", location, params);
      navigate(`/patient-details-v2/${patient_id}/progress/main-goals`);
    } else {
      if (location.pathname) {
        const activeTab =
          tabs.find((tab) => location.pathname.includes(tab.path))?.path ||
          "progress";

        const activeMenuFromPath = Object.keys(leftMenu).find((key) =>
          location.pathname.includes(key)
        );
        if (activeMenuFromPath) {
          // setActiveLeftMenu(activeMenuFromPath);
          // Find the active submenu based on the path
          // @ts-ignore
          const activeSubMenu = leftMenu[activeMenuFromPath].find((menu) =>
            location.pathname.includes(menu.path)
          );
          if (activeSubMenu?.submenu) {
            setSubMenuOpen(activeSubMenu.path); // Open the submenu for the active menu
          } else {
            setSubMenuOpen(null); // No submenu, close it
          }
        }
      }
    }
  }, [location.pathname, params]);

  return (
    <div className="layout-container">
      <UserProfileCard />
      <div>
        <div className="tabs-wrapper">
          <ul className="tabs-container">
            {tabs.map((tab) => (
              <li key={tab.path}>
                <button
                  className={`tab ${activeTab === tab.path && "active-tab"}`}
                  onClick={() => {
                    // @ts-ignore
                    if (leftMenu[tab.path]?.length > 0) {
                      // @ts-ignore
                      if (leftMenu[tab.path][0]?.submenu?.length > 0) {
                        // @ts-ignore
                        navigate(leftMenu[tab.path][0].submenu[0].path);
                      } else {
                        // @ts-ignore
                        navigate(leftMenu[tab.path][0].path);
                      }
                    } else {
                      navigate(tab.path);
                    }
                  }}
                >
                  {tab.label}
                </button>
              </li>
            ))}
          </ul>
        </div>
      </div>
      <div className="content-container">
        {/* Sidebar */}
        {/* @ts-ignore */}
        {leftMenu[activeTab]?.length > 0 && (
          <div className="sidebar">
            <ul className="menu-container">
              {/* @ts-ignore */}
              {leftMenu[activeTab]?.map((menu: any) => (
                <li className="menu-item" key={menu.path}>
                  <button
                    className={`menu-item-button ${
                      (location.pathname.includes(menu.path) ||
                        subMenuOpen === menu.path) &&
                      "active-menu"
                    }`}
                    onClick={() => {
                      if (menu?.submenu?.length > 0) {
                        setSubMenuOpen(subMenuOpen ? null : menu.path);
                      } else {
                        navigate(menu.path);
                      }
                    }}
                  >
                    {menu.label}
                  </button>
                  {subMenuOpen &&
                    menu?.submenu &&
                    menu?.submenu?.length > 0 && (
                      <ul className="submenu-container">
                        {menu?.submenu?.map((subMenu: any) => (
                          <li className="submenu-item" key={subMenu.path}>
                            <button
                              className={`submenu-item-button ${
                                location.pathname.includes(subMenu.path) &&
                                "active-submenu"
                              }`}
                              onClick={() => {
                                navigate(subMenu.path);
                              }}
                            >
                              {subMenu.label}
                            </button>
                          </li>
                        ))}
                      </ul>
                    )}
                </li>
              ))}
            </ul>
          </div>
        )}
        <div className="main-content">
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default PatientLayout;
