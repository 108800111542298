import React from "react";
import "./Select.scss";

interface Option {
  label: string;
  value: string | number;
}

interface SelectProps {
  id?: string; // id for the select field
  name?: string; // name for the select field
  label?: string; // Label for the select field
  error?: boolean; // Whether the select field has an error
  helperText?: string; // Text shown below the select field
  options: Array<string | Option>; // Options for the select field
  value?: string | number; // Current selected value
  onChange: (value: string | number) => void; // Callback for when value changes
}

const Select: React.FC<SelectProps> = ({
  id = "select-input",
  name = "select-input",
  label,
  error = false,
  helperText = "",
  options,
  value,
  onChange,
}) => {
  const renderOptions = () => {
    return options.map((option, index) => {
      if (typeof option === "string") {
        return (
          <option key={index} value={option}>
            {option}
          </option>
        );
      }
      return (
        <option key={index} value={option.value}>
          {option.label}
        </option>
      );
    });
  };

  const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    onChange(event.target.value);
  };

  return (
    <div className={`select-container ${error ? "error" : ""}`}>
      {label && <label className="select-label">{label}</label>}
      <select
        id={id}
        name={name}
        className={`select-field ${error ? "error" : ""}`}
        value={value}
        onChange={handleChange}
        aria-invalid={error}
      >
        <option value="" disabled>
          -- Select an option --
        </option>
        {renderOptions()}
      </select>
      {helperText && (
        <div className={`helper-text ${error ? "error-text" : ""}`}>
          {helperText}
        </div>
      )}
    </div>
  );
};

export default Select;
