import React from "react";
import "./Textarea.scss";

interface Props {
  id?: string;
  label: string;
  name: string;
  onChange: (event: any) => any;
  value: string;
  rows?: number;
  placeholder?: string;
  error?: boolean;
  helperText?: string;
}

const Textarea: React.FC<Props> = ({
  id = "",
  label = "",
  name = "",
  onChange,
  value,
  rows = 4,
  placeholder,
  error,
  helperText,
}) => {
  return (
    <div className="textarea-container">
      {label && <label className="textarea-label">{label}</label>}
      <textarea
        id={id}
        name={name}
        className={`textarea-field ${error ? "error" : ""}`}
        onChange={onChange}
        value={value}
        rows={rows}
        placeholder={placeholder}
      />
      {helperText && (
        <span className={`helper-text ${error ? "error-text" : ""}`}>
          {helperText}
        </span>
      )}
    </div>
  );
};

export default Textarea;
