import React from "react";

import "./Input.style.scss";

interface Props {
  id?: string;
  name?: string;
  type?: string;
  label?: string;
  onChange?: any;
  value?: string;
  placeholder?: string;
  error?: boolean;
  helperText?: string;
}

const Input: React.FC<Props> = ({
  id,
  name,
  type,
  label,
  onChange,
  value,
  placeholder,
  error,
  helperText,
}) => {
  return (
    <div className="input-container">
      {label && <label className="input-label">{label}</label>}
      <input
        id={id}
        className={`input-field ${error ? "error" : ""}`}
        name={name}
        type={type}
        onChange={onChange}
        value={value}
        placeholder={placeholder}
      />
      {helperText && (
        <span className={`helper-text ${error ? "error-text" : ""}`}>
          {helperText}
        </span>
      )}
    </div>
  );
};

export default Input;
