import { generateQueryParams } from '../../helpers';
import Ajax from './base';
import uploadFile from './fileUpload';

export const GetCaloriesAdherence = (payload) => {
    return Ajax.Request(`/diet/calories_adherence_patient`, Ajax.POST, true, payload);
}

// export const GetCaloriesAdherenceOld = (payload) => {
//     return Ajax.Request(`/patient_hc/calories_adherence`, Ajax.POST, true, payload);
// }

export const GetDietPlan = (payload) => {
    return Ajax.Request(`/diet/diet_plan_list`, Ajax.POST, true, payload);
}

export const GetDietPlanById = (payload) => {
    return Ajax.Request(`/patient_hc/get_diet_plan_by_id`, Ajax.POST, true, payload);
}

export const GetFoodItems = (payload) => {
    return Ajax.Request(`/diet/get_food_items`, Ajax.POST, true, payload);
}

export const GetFoodItemNutritions = (payload) => {
    return Ajax.Request(`/diet/food_item_nutritions`, Ajax.POST, true, payload);
}

export const GetFoodItemsOptions = (payload) => {
    return Ajax.Request(`/diet/get_food_items`, Ajax.POST, true, payload);
    // return Ajax.Request(`/goal_readings/search_food_hc`, Ajax.POST, true, payload);
}

export const CreateDietPlan = (payload) => {
    return Ajax.Request(`/patient_hc/create_diet_plan`, Ajax.POST, true, payload);
}

export const CreateDietPlanNew = (payload) => {
    return Ajax.Request(`/diet/update_diet_plan`, Ajax.POST, true, payload);
}

export const GetPatientAllergies = (payload) => {
    return Ajax.Request(`/patient_hc/get_patient_allergies_hc`, Ajax.POST, true, payload)
}

export const GetPatientPreference = (payload) => {
    return Ajax.Request(`/patient_hc/get_diet_preferences_hc`, Ajax.POST, true, payload)
}

export const UpdateDietPlan = (payload) => {
    return Ajax.Request(`/diet/update_diet_plan`, Ajax.POST, true, payload)
}

export const DeleteFoodItem = (payload) => {
    return Ajax.Request(`/patient_hc/delete_food_item`, Ajax.POST, true, payload)
}

export const AddPatientAllergy = (payload) => {
    return Ajax.Request(`/patient_hc/add_patient_allergy`, Ajax.POST, true, payload)
}

export const AddPatientPreference = (payload) => {
    return Ajax.Request(`/patient_hc/add_diet_preference_hc`, Ajax.POST, true, payload)
}

export const CopyDietPlan = (payload) => {
    return Ajax.Request(`/patient_hc/clone_diet_plan`, Ajax.POST, true, payload)
}

//Assessment APIs
export const GetAssessmentQuestions = (payload) => {
    return Ajax.Request('/patient_hc/get_assessment_questions', Ajax.POST, true, payload)
}

export const SubmitAssessmentForm = (payload) => {
    return Ajax.Request('/patient_hc/submit_patient_assessment', Ajax.POST, true, payload)
}

export const UpdateAssessmentForm = (payload) => {
    return Ajax.Request('/patient_hc/update_patient_assessment', Ajax.POST, true, payload)
}

export const GetPatientAssessments = (payload) => {
    return Ajax.Request('/patient_hc/get_patient_assessments', Ajax.POST, true, payload)
}

export const DeletePatientAssessment = (payload) => {
    return Ajax.Request('/patient_hc/delete_patient_assessment', Ajax.POST, true, payload)
}

export const GetPatientData = (payload) => {
    return Ajax.Request('/healthcoach/patient_details_by_id', Ajax.POST, true, payload)
}

//Get HC / Chief HC profile
export const GetProfileDetails = () => {
    return Ajax.Request('/healthcoach/get_profile_details', Ajax.POST, true)
}

//Chief HC side APIs
export const GetDashboardHcList = (payload) => {
    return Ajax.Request('/chief_hc/get_dashboard_healthcoach_list', Ajax.POST, true, payload)
}

export const GetPatientList = (payload) => {
    return Ajax.Request('/chief_hc/hc_patient_list', Ajax.POST, true, payload)
}

export const GetPatients = (payload) => {
    return Ajax.Request(`/healthcoach/patient_list`, Ajax.POST, true, payload)
}

export const GetPatientDetails = (payload) => {
    return Ajax.Request('/chief_hc/hc_patient_details_by_id', Ajax.POST, true, payload)
}

export const GetDashboardCount = (payload) => {
    return Ajax.Request('/chief_hc/chief_dashboard_count', Ajax.POST, true, payload)
}

export const GetOverallPatientCount = (payload) => {
    return Ajax.Request('/chief_hc/get_overall_patient_count', Ajax.POST, true, payload)
}

export const GetPatientReportData = (payload) => {
    return Ajax.Request('/patient_hc/get_patient_report_data', Ajax.POST, true, payload)
}

export const GetPendingDietPlans = (payload) => {
    return Ajax.Request('/chief_hc/get_pending_diet_plans', Ajax.POST, true, payload)
}

export const GetPendingDietPlansUpdated = (payload) => {
    return Ajax.Request('/chief_hc_updated/get_pending_diet_plans_updated', Ajax.POST, true, payload)
}

export const UpdateDietPlanStatusByChief = (payload) => {
    return Ajax.Request('/chief_hc_updated/update_diet_plan_status', Ajax.POST, true, payload)
}

export const UploadPdf = (payload) => {
    return Ajax.Request('/azure_services/push_to_azure_blob_storage', Ajax.POST, true, payload)
}

export const CreateReport = (payload) => {
    return Ajax.Request('/patient_hc/create_patient_report', Ajax.POST, true, payload)
}

export const GetPatientReport = (payload) => {
    payload.containerName = "health-coach-smart-report"
    return Ajax.Request('/patient_hc/get_patient_reports', Ajax.POST, true, payload)
}

export const GetPendingApprovalReports = (payload) => {
    console.log(payload)
    payload.containerName = "health-coach-smart-report"
    return Ajax.Request('/chief_hc/get_pending_reports', Ajax.POST, true, payload)
}

export const UpdateReportStatus = (payload) => {
    return Ajax.Request('/chief_hc/update_reports_status', Ajax.POST, true, payload)
}

export const GetDiseasesData = () => {
    return Ajax.Request('/patient_hc/get_assessment_disease_list', Ajax.POST, true)
}

export const GetDashboardAssessmentGraph = (payload) => {
    return Ajax.Request('/chief_hc/assessment_graph_data', Ajax.POST, true, payload)
}

export const GetIndicationWisePatientData = (payload) => {
    return Ajax.Request('/chief_hc/indication_wise_patients_data', Ajax.POST, true, payload)
}

export const GetExerciseList = (payload) => {
    return Ajax.Request('/healthcoach/exercise_plan/current_exercise_plan', Ajax.POST, true, payload)
}

export const GetPlanUpcomingDueData = (payload) => {
    return Ajax.Request('/chief_hc/plan_expiration_listing', Ajax.POST, true, payload)
}

export const GetAppointmentGraph = (payload) => {
    return Ajax.Request('/healthcoach/availability/get_appointment_graph', Ajax.POST, true, payload)
}

export const GetAllGetActyvPlanByUserId = (payload) => {
    return Ajax.Request('/get_active/fetch-all-plans', Ajax.POST, true, payload);
}

export const GetActyvExerciseList = () => {
    return Ajax.Request('/get_active/get-exercise', Ajax.GET, true);
}

export const AddGetActyvPlan = (payload) => {
    return Ajax.Request('/get_active/add-plan', Ajax.POST, true, payload);
}

export const AddGetActyvSession = (payload) => {
    return Ajax.Request('/get_active/add-session', Ajax.POST, true, payload);
}

export const DeleteGetActyvPlan = (planId) => {
    return Ajax.Request(`/get_active/delete-plan/${planId}`, Ajax.DELETE, true);
}

export const GetUserSavedQNA = (payload) => {
    return Ajax.Request('/get_active/get-saved-answers', Ajax.POST, true, payload);
}

export const GetUserPlan = (planId) => {
    return Ajax.Request('/get_active/get-plan' + '?planId=' + planId, Ajax.GET, true);
}

export const GetUserPlanSession = (sessionId) => {
    return Ajax.Request('/get_active/get-session' + '?sessionId=' + sessionId, Ajax.GET, true);
}

export const GetUserPlanAssessment = (assessmentId) => {
    return Ajax.Request('/get_active/get-assessment/' + assessmentId, Ajax.GET, true);
}

export const GetAssessmentResults = (assessmentId) => {
    return Ajax.Request('/get_active/get-assessment-result/' + assessmentId, Ajax.GET, true);
}

export const GetExercisePlanResults = (payload) => {
    return Ajax.Request('/get_active/get-all-plan-result', Ajax.POST, true, payload);
}

//diet template apis

export const GetDietTemplateList = (params) => {
    const queryString = generateQueryParams(params)
    return Ajax.Request(`/chief_hc_updated/diet_template_list?${queryString}`, Ajax.GET,  true)
}

export const UpdateTemplateList = (template_id, payload) => {
    return Ajax.Request(`/chief_hc_updated/update_diet_template/${template_id}`, Ajax.PUT, true, payload)
}
export const DeleteTemplateList = (template_id) => {
    return Ajax.Request(`/chief_hc_updated/delete_diet_template/${template_id}`, Ajax.DELETE, true);
}

export const CreateDietTemplate = (payload) => {
    return Ajax.Request('/chief_hc_updated/create_diet_template', Ajax.POST, true, payload)
}

export const GetDietTemplateByID = (template_id) => {
    return Ajax.Request(`/chief_hc_updated/diet_template/${template_id}`, Ajax.GET, true)
}

export const GetRecords = (payload) => {
    return Ajax.Request('/healthcoach/fetch_prescription_records', Ajax.POST, true, payload)
}

export const GetRecordTypes = () => {
    return Ajax.Request('/healthcoach/test_types', Ajax.POST, true)
}

export const FileUpload = (files) => {
    return uploadFile('/upload_files', files)
}

export const UploadRecords = (payload) => {
    return Ajax.Request('/healthcoach/update_prescription_record', Ajax.POST, true, payload)
}


let API = {
    GetCaloriesAdherence,
    GetDietPlan,
    GetDietPlanById,
    GetFoodItems,
    GetFoodItemNutritions,
    GetFoodItemsOptions,
    CreateDietPlan,
    CreateDietPlanNew,
    GetPatientAllergies,
    GetPatientPreference,
    UpdateDietPlan,
    DeleteFoodItem,
    AddPatientAllergy,
    AddPatientPreference,
    CopyDietPlan,
    GetAssessmentQuestions,
    SubmitAssessmentForm,
    UpdateAssessmentForm,
    GetProfileDetails,
    GetPatientAssessments,
    GetDashboardHcList,
    GetPatientList,
    GetPatients,
    GetPatientDetails,
    DeletePatientAssessment,
    GetDashboardCount,
    GetOverallPatientCount,
    GetPatientReportData,
    GetPendingDietPlans,
    GetPendingDietPlansUpdated,
    UpdateDietPlanStatusByChief,
    GetPatientData,
    UploadPdf,
    CreateReport,
    GetPatientReport,
    GetPendingApprovalReports,
    UpdateReportStatus,
    GetDiseasesData,
    GetDashboardAssessmentGraph,
    GetIndicationWisePatientData,
    GetExerciseList,
    GetPlanUpcomingDueData,
    GetAppointmentGraph,
    GetAllGetActyvPlanByUserId,
    GetActyvExerciseList,
    AddGetActyvPlan,
    AddGetActyvSession,
    DeleteGetActyvPlan,
    GetUserSavedQNA,
    GetUserPlan,
    GetUserPlanSession,
    GetUserPlanAssessment,
    GetAssessmentResults,
    GetExercisePlanResults,
    GetDietTemplateList,
    UpdateTemplateList,
    DeleteTemplateList,
    GetDietTemplateByID,
    CreateDietTemplate,
    GetRecords,
    GetRecordTypes,
    FileUpload,
    UploadRecords,
}

export default API;