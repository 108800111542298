import React, { useEffect } from "react";

import Input from "../../../atoms/Input/Input";
import Select from "../../../atoms/Select/Select";
import Button from "../../../atoms/Button/Button";

import "./RecordsFilter.scss";
import useApi from "../../../hooks/useApi";
import API from "../../../services/rest/api";

import { ReloadOutlined } from "@ant-design/icons";

interface Props {
  handleApplyFilter: (filters: any) => void;
  handleClearFilters: () => void;
  showUploadRecordModal: () => void;
  refresh: () => void;
}

const RecordsFilter: React.FC<Props> = ({
  handleApplyFilter,
  handleClearFilters,
  showUploadRecordModal,
  refresh,
}) => {
  const [filters, setFilters] = React.useState({
    search: "",
    recordType: "",
  });
  const [recordTypes, setRecordTypes] = React.useState<any>([]);

  const {
    mutate: getRecordTypes,
    response: getRecordsResponse,
    error: getRecordsError,
    isLoading: getRecordsLoading,
  } = useApi(API.GetRecordTypes);

  useEffect(() => {
    getRecordTypes();
  }, []);

  useEffect(() => {
    if (getRecordsResponse && Number(getRecordsResponse.code || 0) === 1) {
      setRecordTypes(getRecordsResponse.data);
    } else if (getRecordsError) {
      console.error("Error fetching record types: ", getRecordsError);
    }
  }, [getRecordsResponse, getRecordsError]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement> | any) => {
    setFilters({
      ...filters,
      [e.target.name]: e.target.value,
    });
  };

  const handleApply = (e: any) => {
    e?.preventDefault();
    if (filters?.search || filters?.recordType) {
      handleApplyFilter(filters);
    }
  };

  const handleClear = (e: any) => {
    e?.preventDefault();
    if (filters?.search || filters?.recordType) {
      setFilters({
        search: "",
        recordType: "",
      });
      handleClearFilters();
    }
  };

  const refreshWrapper = (e: any) => {
    handleClear(e);
    refresh();
  };

  return (
    <div className="records-filter-container">
      <div className="filter-options">
        <div className="title">Search Options</div>
        <Input
          label="Search by Name"
          placeholder="Search by Name"
          type="text"
          name="search"
          id="search"
          value={filters.search}
          onChange={handleInputChange}
        />
        <Select
          label="Search By Record Type"
          options={recordTypes?.map((type: any) => ({
            label: type?.test_name,
            // value: type?.test_type_id,
            value: type?.test_name,
          }))}
          value={filters.recordType}
          onChange={(value) =>
            handleInputChange({ target: { name: "recordType", value } })
          }
          // helperText="Invalid value"
          // error={true}
        />

        <Button className="apply-btn" onClick={handleApply}>
          Apply
        </Button>
        <Button className="apply-btn" variant="outlined" onClick={handleClear}>
          Clear
        </Button>
      </div>

      <div className="rightside-actions-container">
        <Button className="refresh-btn" onClick={refreshWrapper}>
          <ReloadOutlined />
        </Button>
        <Button className="upload-btn" onClick={showUploadRecordModal}>
          + Upload Record
        </Button>
      </div>
    </div>
  );
};

export default RecordsFilter;
