import React from 'react'

import "./VitalsPage.scss"

const VitalsPage = () => {
  return (
    <div>VitalsPage</div>
  )
}

export default VitalsPage