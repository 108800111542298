import React, { useEffect, useState } from "react";

import "./UploadRecordModal.scss";
import Input from "../../../atoms/InputNew/Input";
import Select from "../../../atoms/Select/Select";
import Button from "../../../atoms/Button/Button";
import Textarea from "../../../atoms/TextArea/Textarea";
import FileUpload from "../../../atoms/FileUpload/FileUpload";

import useApi from "../../../hooks/useApi";

import API from "../../../services/rest/api";
import LOCAL_STORAGE from "../../../constants/localStorage";
import { toast } from "react-toastify";

interface Props {
  onSuccess: () => void;
  onCancel: () => void;
}

const UploadRecordModal: React.FC<Props> = ({ onSuccess, onCancel }) => {
  const [recordTypes, setRecordTypes] = React.useState<any>([]);
  const [formData, setFormData] = useState<{
    title: string;
    description: string;
    recordType: string;
    type: string;
    document_data: Array<File> | null;
  }>({
    title: "",
    description: "",
    recordType: "", // test_type_id: "",
    type: "R",
    document_data: null,
  });

  const [formErrors, setFormErrors] = useState<{
    title: string;
    description: string;
    recordType: string;
    type: string;
    document_data: string;
  }>({
    title: "",
    description: "",
    recordType: "", // test_type_id: "",
    type: "",
    document_data: "",
  });

  const requiredFields: Array<keyof typeof formData> = [
    "title",
    // "description",
    "recordType",
    // "type",
    "document_data",
  ];

  const {
    mutate: getRecordTypes,
    response: getRecordsResponse,
    error: getRecordsError,
    isLoading: getRecordsLoading,
  } = useApi(API.GetRecordTypes);

  const {
    mutate: fileUpload,
    response: fileUploadResponse,
    error: fileUploadError,
    isLoading: fileUploadLoading,
  } = useApi(API.FileUpload);

  const {
    mutate: uploadRecords,
    response: uploadResponse,
    error: uploadError,
    isLoading: uploadRecordsLoading,
  } = useApi(API.UploadRecords);

  useEffect(() => {
    getRecordTypes();
  }, []);

  useEffect(() => {
    if (getRecordsResponse && Number(getRecordsResponse.code || 0) === 1) {
      setRecordTypes(getRecordsResponse.data);
    } else if (getRecordsError) {
      console.error("Error fetching record types: ", getRecordsError);
    }
  }, [getRecordsResponse, getRecordsError]);

  const validate = () => {
    const errors: any = formErrors;

    requiredFields.map((fieldName) => {
      if (!formData[fieldName]) {
        // Add your validation logic here
        errors[fieldName] = "Required!";
      } else {
        errors[fieldName] = "";
      }
    });

    setFormErrors({ ...errors });

    const isValid = Object.keys(errors).every(
      (fieldName) => !errors[fieldName]
    );

    return isValid;
  };

  const handleUpload = async (event: any) => {
    event.preventDefault();

    // validations
    if (validate()) {
      // step 1: File Upload
      if (formData.document_data && formData.document_data.length > 0) {
        await fileUpload(formData.document_data[0]);
      }
      // onSuccess();
    }
  };

  useEffect(() => {
    if (fileUploadResponse && Number(fileUploadResponse.code || 0) === 1) {
      // step 2: Store data

      const patientId = localStorage.getItem(LOCAL_STORAGE.PATIENT_ID);

      const documentData = [fileUploadResponse?.data?.file_name];

      const payload = {
        patient_id: patientId,
        type: formData?.type,
        document_data: documentData,
        description: formData?.description,
        test_type_id: formData?.recordType,
        title: formData?.title,
      };

      uploadRecords(payload);
    } else if (fileUploadError) {
      console.error("Error uploading record file: ", fileUploadError);
    }
  }, [fileUploadResponse, fileUploadError]);

  useEffect(() => {
    if (uploadResponse && Number(uploadResponse?.code || 0) === 1) {
      toast.success("Record Uploaded Successfully!");
      onSuccess();
    }
  }, [uploadResponse, uploadError]);

  const handleFileUpload = (files: Array<File>) => {
    setFormData({
      ...formData,
      document_data: files,
    });
  };

  const handleChange = (event: any) => {
    const fieldName = event.target.name;
    const fieldValue = event.target.value;

    setFormData({
      ...formData,
      [fieldName]: fieldValue,
    });

    const errors = { ...formErrors };

    if (requiredFields.includes(fieldName)) {
      setFormErrors({
        ...errors,
        [fieldName]: !fieldValue
          ? "Required!"
          : String(fieldValue).length < 3
          ? "Minimum 3 char are required!"
          : "",
      });
    }
  };

  return (
    <div className="upload-records-modal-container">
      <div className="upload-records-form">
        <Input
          id="recordTitle"
          name="title"
          label="Record Name *"
          type="text"
          placeholder="Enter Record Name"
          value={formData?.title}
          onChange={handleChange}
          error={!!formErrors.title}
          helperText={formErrors.title}
        />

        <Select
          id="recordType"
          name="recordType"
          label="Record Type *"
          options={recordTypes?.map((type: any) => ({
            label: type?.test_name,
            value: type?.test_type_id,
          }))}
          onChange={(value) =>
            handleChange({ target: { name: "recordType", value } })
          }
          value={formData?.recordType}
          error={!!formErrors.recordType}
          helperText={formErrors.recordType}
        />

        <Textarea
          label="Description"
          placeholder="Enter Description"
          name="description"
          id="description"
          onChange={handleChange}
          value={formData?.description}
          error={!!formErrors.description}
          helperText={formErrors.description}
        />

        <FileUpload
          onFileUpload={handleFileUpload}
          error={!!formErrors.document_data}
          helperText={formErrors.document_data}
        />
      </div>

      <div className="upload-records-footer">
        <Button
          variant="outlined"
          onClick={onCancel}
          fullWidth={true}
          disabled={fileUploadLoading || uploadRecordsLoading}
          loading={fileUploadLoading || uploadRecordsLoading}
        >
          Cancel
        </Button>

        <Button
          variant="primary"
          onClick={handleUpload}
          fullWidth={true}
          disabled={fileUploadLoading || uploadRecordsLoading}
          loading={fileUploadLoading || uploadRecordsLoading}
        >
          Upload
        </Button>
      </div>
    </div>
  );
};

export default UploadRecordModal;
