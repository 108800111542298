import React from "react";

import "./UserProfileCard.scss";
import { Avatar } from "antd";
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  UserOutlined,
} from "@ant-design/icons";
import Button from "../../../atoms/Button/Button";

const UserProfileCard: React.FC = () => {
  return (
    <div className="patient-details-card-container">
      <div className="patient-avatar-and-info">
        <Avatar size={48} icon={<UserOutlined />} />
        <div>
          <div className="patient-info">
            <div className="patient-name">Venugopala S</div>
            <div className="patient-gender">Male</div>
            <div className="minus-symbol">-</div>
            <div className="patient-age">28 Years</div>
          </div>
        </div>
      </div>

      <div className="features-wrapper">
        <div className="feature">
          <div className="feature-label">Assigned Plan</div>
          <div className="feature-value">Metabolic - Diabetes</div>
        </div>
        <div className="feature">
          <div className="feature-label">Plan Duration</div>
          <div className="feature-value">6 Months</div>
        </div>

        <div className="feature feature-service">
          <div className="feature-label">Optional Services</div>
          <div className="feature-services-value">
            <div>
              Supplements <CheckCircleOutlined className="checked" />
            </div>
            <div>
              GSM Monitor <CloseCircleOutlined className="unchecked" />
            </div>
          </div>
        </div>
      </div>
      <div className="action-button-wrapper">
        <Button
          label={"View User Details"}
          variant="secondary"
          onClick={() => {}}
        />
        <Button label={"Chat with User"} variant="primary" onClick={() => {
          window.parent.postMessage(
            {
              messageType: 'goToChat',
            },
            "*"
          );
        }} />
      </div>
    </div>
  );
};

export default UserProfileCard;
