import React, { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import moment from "moment";
// import { saveAs } from "file-saver";
import "./PatientDetailScreen.scss";
import API from "../../services/rest/api";

const PatientDetailScreen = () => {
  const [activeTab, setActiveTab] = useState("Marker");
  const [patientData, setPatientData] = useState({} as any);
  const [prescriptionList, setPrescriptionList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [showPreviousArrow, setShowPreviousArrow] = useState(false);
  const [showNextArrow, setShowNextArrow] = useState(false);
  const [showScroll, setShowScroll] = useState(false);
  const [splitPane, setSplitPane] = useState(false);
  const { patientId: id } = useParams();
  const [userInfo, setUserInfo] = useState<any>(null);

  console.log("idddd", id);
  // const userInfo = JSON.parse(localStorage.getItem("userInfo")); // Assuming user info is stored in localStorage

  useEffect(() => {
    if (id) {
      getPatientDetail();
      // getPrescription(id);
    }
  }, [id]);

  const getPatientDetail = async () => {
    try {
      const userInfo = await API.GetProfileDetails();
      console.log("data", userInfo);
      if (userInfo.code === "1") {
        setUserInfo(userInfo?.data);
        localStorage.setItem("userInfo", JSON.stringify(userInfo?.data));
        // setPatientData(data.data);
        // localStorage.setItem("patient_age", data.data.patient_age);
      }
    } catch (error) {
      console.error("Error fetching patient details:", error);
    }
  };

  const getPrescription = async (patientId: any, page = 1) => {
    try {
      const response = await fetch("/api/prescriptions", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
        body: JSON.stringify({
          page,
          patient_id: patientId,
        }),
      });
      const data = await response.json();

      if (data.code === "1") {
        setPrescriptionList(data.data);
        setShowScroll(true);
        setShowNextArrow(data.data.length > 7);
      } else {
        setShowScroll(false);
      }
    } catch (error) {
      console.error("Error fetching prescriptions:", error);
    }
  };

  const handleUploadReport = async (event: any) => {
    const file = event.target.files[0];
    if (!file) return;

    const formData = new FormData();
    formData.append("file", file);
    formData.append("patient_id", id || "");

    try {
      const response = await fetch("/api/upload-report", {
        method: "POST",
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
        body: formData,
      });
      const data = await response.json();

      if (data.code === "1") {
        getPrescription(id);
      }
    } catch (error) {
      console.error("Error uploading report:", error);
    }
  };

  const checkPDF = (url: any) => {
    return url.toLowerCase().includes(".pdf");
  };

  const downloadFile = (url: any) => {
    const date = moment().format("DD-MM-YYYY HH-mm");
    const fileName = `${patientData.name}'s Spirometer Report - ${date}.pdf`;
    // saveAs(url, fileName);
  };

  return (
    <div className="patient-detail">
      {/* <header className="header">
        <nav className="breadcrumb">
          <Link to="/home">Home</Link> {" > "}
          <Link to="/patient">Patient</Link>
          {patientData.name && (
            <>
              {" "}
              {" > "} <span>{patientData.name}</span>
            </>
          )}
        </nav>
      </header>

      <div className="patient-card">
        <div className="patient-info">
          <div className="profile-section">
            <img
              src={patientData.profile_pic}
              alt={patientData.name}
              className="profile-image"
            />
            <div className="vital-stats">
              <div className="stat">
                <span>Height:</span>
                <span>
                  {patientData.height ? `${patientData.height} cm` : "-"}
                </span>
              </div>
              <div className="stat">
                <span>Weight:</span>
                <span>
                  {patientData.weight ? `${patientData.weight} kg` : "-"}
                </span>
              </div>
            </div>
          </div>

         
        </div>
      </div> */}
    </div>
  );
};

export default PatientDetailScreen;
