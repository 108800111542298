import React from "react";
import "./Popup.scss";

interface PopupProps {
  show: boolean; // Controls the visibility of the popup
  headerText: string; // Text to display in the header
  onClose: () => void; // Callback for close button click
  children?: React.ReactNode; // Content of the popup
}

const Popup: React.FC<PopupProps> = ({
  show,
  headerText,
  onClose,
  children,
}) => {
  if (!show) return null;

  return (
    <div className="popup-overlay">
      <div className="popup-container">
        <div className="popup-header">
          <h3 className="popup-title">{headerText}</h3>
          <button className="popup-close" onClick={onClose}>
            &times;
          </button>
        </div>
        <div className="popup-content">{children}</div>
      </div>
    </div>
  );
};

export default Popup;
