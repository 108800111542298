export const FILE_UPLOAD_API_URL = `${process.env.REACT_APP_FILE_UPLOAD_API_URL}`;

const uploadFile = async (url, file, additionalFields = {}) => {
  const formData = new FormData();

  // Append the file to the FormData
  formData.append("files", file);

  // Append any additional fields (if provided)
  for (const [key, value] of Object.entries(additionalFields)) {
    formData.append(key, value);
  }

  try {
    const response = await fetch(`${FILE_UPLOAD_API_URL}${url}`, {
      method: "POST",
      body: formData,
    });

    if (!response.ok) {
      throw new Error(`Failed to upload file: ${response.statusText}`);
    }

    const data = await response.json(); // Parse the JSON response
    return data;
  } catch (error) {
    console.error("Error uploading file:", error);
    throw error;
  }
};

export default uploadFile;
