import { useState, useEffect } from 'react';
import {  GetPatients } from '../services/rest/api';

export const usePatient = (searchTerm: string, patientListPagination: any) => {
  const [patients, setPatients] = useState([]);
  const [loading, setLoading] = useState(false);
  const [activeView, setActiveView] = useState('call');
  const [totalActive, setTotalActive] = useState(0);
  const [totalPatients, setTotalPatients] = useState(0);

  const fetchPatients = async () => {
    try {
      setLoading(true);
      const response = await GetPatients({
        is_active: activeView === 'call' ? 'Y' : 'N',
        page: patientListPagination?.page.toString(),
        per_page : patientListPagination?.limit.toString(),
        search: searchTerm,
      });

      if (response.code === '1') {
        setPatients(response.data);
        setTotalPatients(response.total_data);
        if (activeView === 'call') {
          setTotalActive(response.total_data);
        }
      } else {
        setPatients([]);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      fetchPatients();
    }, 500);
  
    return () => clearTimeout(timer);
  }, [activeView, searchTerm, patientListPagination?.page, patientListPagination?.limit]);


  return {
    patients,
    loading,
    totalActive,
    activeView,
    setActiveView,
    totalPatients
  };
};